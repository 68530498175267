@import '../common/mixin';

.bottom-nav-wrap {
  display: none;
}

@include new-mobile {
  .bottom-nav-wrap {
    background-color: #ffffff;
    @include flex(flex-start, center);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 998;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    width: 100%;
    border-top: 1px solid #eaeaea;

    a {
      width: 100%;
      height: 100%;
      display: block;
      padding: 10px 0 11px;
    }

    .nav-item {
      width: 25%;

      img {
        width: 24px;
        height: 24px;
        display: block;
        margin: auto;
      }

      h6 {
        @include font(12px, 400) {
          color: #727272;
        }
        margin-top: 4px;
        text-align: center;
      }

      &.selected {
        h6 {
          color: #4745ff;
        }
      }
    }
  }
}
