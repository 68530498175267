@import 'mixin';
@import 'mixin_animation';

.confirm-container {
  position: fixed;
  top: 30px;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  @include animation(back-in-down-anim, 0.25s, 0s, ease-in-out, forword);
  @include keyframe(back-in-down-anim) {
    0% {
      opacity: 0;
      top: -100px;
    }
    100% {
      opacity: 1;
      top: 30px;
    }
  }
}

.confirm-block-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5001;
}

.confirm-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 320px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 5002;
  -webkit-box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1);
  padding: 24px 16px 16px;

  &.shake-anim {
    @include animation(shake-anim, 0.8s, 0s, linear, both);
    @include keyframe(shake-anim) {
      0%,
      100% {
        transform: translate3d(0, 0, 0);
      }
      10%,
      30%,
      50%,
      70%,
      90% {
        transform: translate3d(-10px, 0, 0);
      }
      20%,
      40%,
      60%,
      80% {
        transform: translate3d(10px, 0, 0);
      }
    }
  }

  p {
    @include font(14px, 400) {
      line-height: 22px;
    }
    white-space: pre-line;
    word-break: keep-all;
  }

  .confirm-btn-group {
    margin-top: 20px;
    @include flex(space-between, center);

    button {
      width: 48%;
      font-size: 12px;
      padding: 6px 0;
    }

    .cancel-btn {
      background-color: #cccccc;
    }
  }
}

@include mobile {
  .confirm-content {
    padding: 18px 12px 12px;
    width: 260px;

    .confirm-btn-group {
      margin-top: 14px;
    }
  }
}
